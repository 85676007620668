import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CartService} from "../../Service/CartService.service";
import {Product} from "../../Model/ProductModel";
import {ProductService} from "../../Service/ProductService";
import {HttpClient} from "@angular/common/http";
import {ProductVariation} from "../../Model/ProductVariation";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatInput} from "@angular/material/input";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  @ViewChildren(MatInput) inputs!: QueryList<MatInput>;
  @ViewChild('main_view') mainView!: ElementRef;
  @ViewChild('side_view') sideView!: ElementRef;
  public id=0;



    amountSelected:number=1;
    filePath:string = "assets/uploads/";
    file:string = "";
  files: string[] = [];
  fileCounter = 0;

  productShown: Product;
  productService: ProductService;
  selectedProductVariations: Array<ProductVariation>;
  optionSelected:ProductVariation;


  constructor(private route: ActivatedRoute, private cartService: CartService, private HttpClient:HttpClient, private snackBar: MatSnackBar, private router:Router) {
    this.productShown=new Product(0, 0, "","",0,0,"",[],[], 0, false, 0, 0);
    this.productService = new ProductService(HttpClient);
    this.selectedProductVariations = new Array<ProductVariation>();
    this.optionSelected = new ProductVariation(0,0,[],0);
  }

  ngAfterViewInit() {
    console.log("Editing");
    console.log(this.sideView);
    console.log(this.mainView)
    const mainViewHeight = this.mainView.nativeElement.offsetHeight;
    this.sideView.nativeElement.style.height = mainViewHeight + 'px';
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const mainViewHeight = this.mainView.nativeElement.offsetHeight;
    this.sideView.nativeElement.style.height = mainViewHeight + 'px';
  }

  async ngOnInit(): Promise<void> {
    this.getProduct();
    this.productShown = <Product> await this.productService.getProductWithId(this.id);
    console.log(this.productShown);
    if(this.productShown.options.length > 0) {
      this.selectedProductVariations = <Array<ProductVariation>>await this.productService.getProductVariationsWithProductId(this.id);
      this.optionSelected = new ProductVariation(0, this.selectedProductVariations[0].product_id, JSON.parse(JSON.stringify(this.selectedProductVariations[0].variation)), this.selectedProductVariations[0].stock);
      console.log(this.selectedProductVariations);

    }
    this.files = this.productShown.headliner_image;
    console.log(this.files);
    console.log(this.productShown.options.length);
    console.log(this.productShown.general_stock);
  }

  getProduct(){
    this.id= Number(this.route.snapshot.paramMap.get('id'));

  }

  findVariation(query:any){
    return this.selectedProductVariations.findIndex(variation => {
      return JSON.stringify(variation.variation) === JSON.stringify(query);
    });
  }

  selectVariant(optionName: any, value: any){
    console.log("Selecting " + optionName + ": " + value);

    let newOption = {...this.optionSelected.variation};
    delete newOption[optionName];
    newOption = {...newOption, [optionName]: value};

    let possibleOptions = this.selectedProductVariations.filter(variation => {
      return Object.keys(newOption).every((key)=>variation.variation[key]===newOption[key])
    });
    if(possibleOptions.length > 0) {console.log("Found option!");this.optionSelected = possibleOptions[0];}
    console.log(possibleOptions);
    console.log(newOption);

    if(this.optionSelected.stock < this.amountSelected) this.amountSelected = this.optionSelected.stock;
    else if(this.optionSelected.stock > 0 && this.amountSelected <= 0) this.amountSelected = 1;
    /*this.optionSelected.variation.forEach((option:any)=>{
      if(optionId == option.id) option.selected = value;
    })

    const selectedOptionIndex = this.findVariation(this.optionSelected.variation);
    //console.log("Index found: " + selectedOptionIndex);


    if(selectedOptionIndex == -1){this.optionSelected.stock = 0}
    else{this.optionSelected = new ProductVariation(0, this.selectedProductVariations[selectedOptionIndex].product_id, JSON.parse(JSON.stringify(this.selectedProductVariations[selectedOptionIndex].variation)), this.selectedProductVariations[selectedOptionIndex].stock);}
    if(this.amountSelected > this.optionSelected.stock) this.amountSelected = this.optionSelected.stock;
    if(this.optionSelected.stock > 0) this.amountSelected = 1;
    if(this.amountSelected < 0) this.amountSelected = 0;*/

  }

  increment(){
    if(this.selectedProductVariations.length > 0){
      if(this.amountSelected + 1 <= this.optionSelected.stock)
        this.amountSelected++;
    }else{
      if(this.amountSelected + 1 <= this.productShown.general_stock)
        this.amountSelected++;
    }
  }

  decrement(){
    console.log("Down");
    if(this.amountSelected > 1) this.amountSelected--;
  }
  addToCart(){

      this.cartService.addToCart(this.productShown, this.optionSelected, this.amountSelected, "");
      console.log("Selected: " + this.amountSelected);
      let addToCartSnackBar = this.snackBar.open(this.amountSelected+"x "+this.productShown.name+" has been added to the cart!", "VIEW CART");
      addToCartSnackBar.onAction().subscribe(()=>{
        this.router.navigate(["cart"]);
      })
    //console.log(this.cartService.getItems())
  }

  changeSelectedAmount(event:any)
  {
    this.amountSelected = parseInt(event.target.value);
  }

  getSelectedValue(optionId:number){
    this.optionSelected.variation.forEach((option:any)=>{
      if(option.id == optionId) return option.selected;
    })
  }

  //PICTURE SLIDESHOW
  public next() : void{
      this.fileCounter++;
      if(this.fileCounter >= this.files.length) this.fileCounter=0;
  }

  public prev() : void{
    this.fileCounter--;
    if(this.fileCounter < 0 ) this.fileCounter=this.files.length-1;
  }

  public changeImage(index:number) : void{
    this.fileCounter = index;
    console.log(index);
  }
  getTextWidth(text:string): number{
    const div = document.createElement('div');
    div.style.display = 'inline-block';
    div.innerText = text;
    div.style.fontSize = `${24}px`;
    div.style.fontFamily = "PPNeueBit-Bold";
    div.style.display = 'inline-block';
    document.body.appendChild(div);
    const width = div.offsetWidth;
    document.body.removeChild(div);
    return width+6;
  }
  getTextHeight(text:string): number{
    const div = document.createElement('div');
    div.style.display = 'inline-block';
    div.innerText = text;
    document.body.appendChild(div);
    const height = div.offsetHeight;
    document.body.removeChild(div);
    return height;
  }

}
