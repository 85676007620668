import { Component, OnInit } from '@angular/core';
import {Product} from "../../Model/ProductModel";
import {HttpClient} from "@angular/common/http";
import {ProductService} from "../../Service/ProductService";
import {ProductVariation} from "../../Model/ProductVariation";
import {Router} from "@angular/router";


@Component({
  selector: 'app-merch',
  templateUrl: './merch.component.html',
  styleUrls: ['./merch.component.css']
})
export class MerchComponent implements OnInit {


  public products:Array<Product>= new Array<Product>();

  constructor(private HttpClient: HttpClient, private productsService: ProductService, private router:Router) {  }


  async ngOnInit(): Promise<void> {
    await this.getProducts();
  }
  async getProducts():Promise<void>{
    let fetchedProducts:any = await this.productsService.getAllProducts({hidden: false}, 1, 9999, {'position': 'asc'});
    this.products = <Array<Product>> fetchedProducts.result;
  }
  async goToProduct(productId:number){
    await this.router.navigateByUrl('/product/'+productId);
  }



}
