import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Product} from '../Model/ProductModel'
import {ProductVariation} from "../Model/ProductVariation";
import {CartItem} from "../Model/CartItemModel";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ProductService{
    HttpClient:HttpClient;

    constructor(HttpClient: HttpClient) {
        this.HttpClient = HttpClient;
    }

    async createNewProduct(product: Product){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return new Promise(resolve=>{
            this.HttpClient.post<Array<Product>>(environment.apiHost+environment.apiPath+"product", product, httpOptions)
                .subscribe(response=>{
                    console.log("Response: " + JSON.stringify(response))
                    resolve(response)
                })
        })
    }

    async deleteProduct(id:number){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{
            this.HttpClient.delete(environment.apiHost+environment.apiPath+"product/"+id, httpOptions)
                .subscribe(response=>{
                    console.log("Response: " + JSON.stringify(response))
                    resolve(response)
                })
        })
    }


    async getAllProducts(filter={}, page=1, itemsPerPage=25, sorting={}){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{

            this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'product?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), httpOptions)
                .subscribe(response=>{
                    console.log("Response: " + JSON.stringify(response))
                    for(let i=0; i<response.result.length; i++){
                        response.result[i].options = JSON.parse(JSON.stringify(response.result[i].options));
                    }
                    //console.log(JSON.stringify(products));
                    resolve(response)
                })
        });

    }

    async getProductWithId(id:number){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{

            this.HttpClient.get<Array<Product>>(environment.apiHost+environment.apiPath+"product/"+id, httpOptions)
                .subscribe(response=>{
                    response[0].options = JSON.parse(JSON.stringify(response[0].options));
                    console.log(JSON.stringify(response));
                    resolve(response[0])
                })
        });
    }

    async getProductVariationsWithProductId(id:number){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{

            this.HttpClient.get<Array<ProductVariation>>(environment.apiHost+environment.apiPath+"product-variation/product/"+id, httpOptions)
                .subscribe(response=>{

                    for(let i=0; i<response.length; i++) {
                        //console.log("Variation: " + productVariation[i].variation);
                        response[i].variation = JSON.parse(JSON.stringify(response[i].variation));
                    }
                    //console.log(JSON.stringify(productVariation));
                    resolve(response)
                })
        });
    }

    async editProductWithId(id: number, product: Product){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{
            //product.variacije = JSON.stringify(product.variacije);
            //console.log("Variacije: " + JSON.stringify(product.options))
            this.HttpClient.put(environment.apiHost+environment.apiPath+"product/"+id, product, httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }

    async saveVariant(variant:ProductVariation){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{
            this.HttpClient.put(environment.apiHost+environment.apiPath+"product-variation/"+variant.id, variant, httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }

    async createVariant(variant:ProductVariation){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{
            //product.variacije = JSON.stringify(product.variacije);
            this.HttpClient.post(environment.apiHost+environment.apiPath+"product-variation", variant,httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }

    async deleteVariationWithId(id:number){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{
            //product.variacije = JSON.stringify(product.variacije);
            this.HttpClient.delete(environment.apiHost+environment.apiPath+"product-variation/"+id, httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }

    async productsSold(products:CartItem | CartItem[]){
        const httpOptions = this.getHttpOptions();
        return new Promise((resolve)=>{
            this.HttpClient.post(environment.apiHost+environment.apiPath+"product/sold", products, httpOptions)
                .subscribe(
                    response=>{resolve(response)}
                )
        });
    }

    getHttpOptions(){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return httpOptions;
    }




}
