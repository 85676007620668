import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Website/home/home.component';
import { MerchComponent } from './Website/merch/merch.component';
import { ProductComponent } from './Website/product/product.component';
import { InfoComponent } from './Website/info/info.component';
import { TourComponent } from './Website/tour/tour.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { LoginComponent } from './Website/login/login.component';
import { RegisterComponent } from './AdminPanel/register/register.component';
import $ from "jquery";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AdminLayoutComponent } from './AdminPanel/admin-layout/admin-layout.component';
import {ComponentsModule} from "./AdminPanel/components/components.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AdminToursComponent } from './AdminPanel/admin-tours/admin-tours.component';
import { AdminOrdersComponent } from './AdminPanel/admin-orders/admin-orders.component';
import { NewProductComponent } from './AdminPanel/admin-products/new-product/new-product.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import { HeaderComponent } from './Website/components/header/header.component';
import { FooterComponent } from './Website/components/footer/footer.component';
import {MatInputModule} from "@angular/material/input";
import {CartComponent} from "./Website/cart/cart.component";
import {CartService} from "./Service/CartService.service";
import { PaymentSuccessComponent } from './Website/payment-success/payment-success.component';
import { PaymentFailedComponent } from './Website/payment-failed/payment-failed.component';
import {StripeService} from "./Service/Stripe.service";
import {CustomerService} from "./Service/Customer.service";
import {ShipmentItemService} from "./Service/ShipmentItem.service";
import {ShipmentService} from "./Service/Shipment.service";
import {PaymentService} from "./Service/Payment.service";
import {TourService} from "./Service/Tour.service";
import {MatPaginatorModule} from "@angular/material/paginator";
import { AdminViewOrderInfoComponent } from './AdminPanel/admin-orders/admin-view-order-info-component/admin-view-order-info.component';
import {OrderItemService} from "./Service/OrderItem.service";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import { AddImageComponent } from './AdminPanel/popups/add-image/add-image.component';
import {MatDialogModule} from "@angular/material/dialog";
import { SelfRegisterComponent } from './Website/self-register/self-register.component';
import {EmployeeService} from "./Service/EmployeeService";
import { PreorderPopupComponent } from './AdminPanel/popups/preorder-popup/preorder-popup.component';
import { EmployeesComponent } from './AdminPanel/employees/employees.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { CookiesComponent } from './Website/cookies/cookies.component';
import { ReturnAndRefundComponent } from './Website/return-and-refund/return-and-refund.component';
import { TermsOfBusinessComponent } from './Website/terms-of-business/terms-of-business.component';
import { PrivacyPolicyComponent } from './Website/privacy-policy/privacy-policy.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {environment} from "../environments/environment";
import { CompanyInformationComponent } from './Website/company-information/company-information.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { SubscribeComponent } from './AdminPanel/popups/subscribe/subscribe.component';
import { UnsubscribeComponent } from './Website/unsubscribe/unsubscribe.component';
import {NgOptimizedImage} from "@angular/common";
import { SendSubscriptionMailComponent } from './AdminPanel/send-subscription-mail/send-subscription-mail.component';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {MatButton} from "@angular/material/button";


const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
        domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#f1d600'
        }
    },
    theme: 'edgeless',
    type: 'opt-out'
};
//c
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MerchComponent,
        ProductComponent,
        InfoComponent,
        TourComponent,
        LoginComponent,
        RegisterComponent,
        HeaderComponent,
        FooterComponent,
        CartComponent,
        PaymentSuccessComponent,
        PaymentFailedComponent,
        AddImageComponent,
        SelfRegisterComponent,
        PreorderPopupComponent,
        EmployeesComponent,
        CookiesComponent,
        ReturnAndRefundComponent,
        TermsOfBusinessComponent,
        PrivacyPolicyComponent,
        CompanyInformationComponent,
        SubscribeComponent,
        UnsubscribeComponent,
        SendSubscriptionMailComponent,
    ],
    imports: [
        NgcCookieConsentModule.forRoot(cookieConfig),
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        ComponentsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        NgOptimizedImage,
        CKEditorModule,
        MatButton,
    ],
    providers: [
        CartService,
        StripeService,
        CustomerService,
        ShipmentItemService,
        ShipmentService,
        PaymentService,
        TourService,
        OrderItemService,
        EmployeeService
    ],
    exports: [
        HeaderComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule {

}
