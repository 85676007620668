<!--PC Header t-->
<div class="header">
  <div class="row">
    <div class="column"><a href="home"> <img class="logo" src="assets/img/gumbi-02.svg" style="width: 200px"></a></div>
  </div>
  <div class="column">
    <a href="merch"><img class="scale btn-shake" src="assets/img/gumbi-03.svg"></a>
    <a href="https://album.link/i/1620553900" target="_blank"><img class="scale btn-shake" src="assets/img/gumbi-05.svg"></a>
    <a href="tour"><img class="scale btn-shake" src="assets/img/gumbi-04.svg"></a>
    <a href="info"><img class="scale btn-shake" src="assets/img/gumbi-06.svg"></a>
  </div>

  <div class="column">
    <a href="https://www.facebook.com/MRFYnm/" target="_blank"><img class="socials-desktop" src="assets/img/SOCIAL_LOGO-01.svg"></a>
    <a href="https://www.instagram.com/mrfy_band/" target="_blank"><img class="socials-desktop" src="assets/img/SOCIAL_LOGO-02.svg"></a>
    <a href="https://www.tiktok.com/@mrfyisthenew"  target="_blank"><img class="socials-desktop" src="assets/img/SOCIAL_LOGO-03.svg"></a>
    <a href="https://music.apple.com/si/artist/mrfy/1193215834" target="_blank"><img class="socials-desktop" src="assets/img/SOCIAL_LOGO-04.svg"></a>
    <a href="https://open.spotify.com/artist/0nWEe4MRacRVVWT71b9PeM" target="_blank"><img class="socials-desktop" src="assets/img/SOCIAL_LOGO-05.svg"></a>
    <a href="https://www.youtube.com/channel/UCfBQmBpRjEsIBUD9KbxxxQQ" target="_blank"><img class="socials-desktop ml2" src="assets/img/SOCIAL_LOGO-06.svg"></a>
  </div>
  <div class="column">
    <a href="https://mrfyland.mrfy.net">
      <img src="assets/img/thumbnail_mrfyland_logo.png" height="50px" />
    </a>
  </div>
  <div class="column">
      <div class="cart-container">
          <img class="store-cart" (click)="this.router.navigateByUrl('cart')" src="assets/img/gumbi-01.svg">
          <div class="cart-badge" *ngIf="cartItemsCount > 0">
              <span class="cart-badge-count">{{cartItemsCount}}</span>
          </div>
      </div>

  </div>
</div>
<!--Mobile Header-->
<div class="header-m">
    <div class="row-mobile">
        <div class="column-mobile">
            <a href="home"> <img class="logo-mobile" src="assets/img/gumbi-02.svg"></a></div>
        <div class="column-mobile">
            <a class="btn-info-mobile" href="info" ></a></div>
        <div class="column-mobile">
            <div class="cart-container">
                <img class="store-cart-mobile" (click)="this.router.navigateByUrl('cart')" src="assets/img/gumbi-01.svg">
                <div class="cart-badge mobile" *ngIf="cartItemsCount > 0">
                    <span class="cart-badge-count">{{cartItemsCount}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
