
<!-- BODY -->
<app-header></app-header>
<div class="container-info">
    <div class="row-info">
        <div class="column-info">
            <h1 class="info-title">INFO</h1>
            <div class="info-top">booking:</div>
            <div class="info-sub">booking&#64;mrfy.net</div>
            <div class="info-top">management:</div>
            <div class="info-sub">management&#64;mrfy.net</div>
            <div class="info-top">random:</div>
            <div class="info-sub">info&#64;mrfy.net</div>
            <div class="info-top">web shop:</div>
            <div class="info-sub">shop&#64;mrfy.net</div>

        </div>
    </div>

    <div class="row-info" style="padding-top:10px;">
        <div class="column-info">
            <h1 class="info-title">Webshop</h1>
            <div class="info-sub link" (click)="this.router.navigateByUrl('company-information')">> Company Information</div>
            <div class="info-sub link" (click)="this.router.navigateByUrl('return-and-refund')">> Return & Refund</div>
            <div class="info-sub link" (click)="this.router.navigateByUrl('terms-of-business')">> Terms of business</div>
            <div class="info-sub link" (click)="this.router.navigateByUrl('privacy-policy')">> Privacy Policy</div>
        </div>
    </div>


    <div class="container-info-mobile">
        <div class="info-tour-mobile">INFO</div>
        <div class="row-info-mobile">
            <div class="column-info-mobile">
                <div class="info-top-mobile">booking:</div>
                <div class="info-sub-mobile">booking&#64;mrfy.net</div>
                <div class="info-top-mobile">management:</div>
                <div class="info-sub-mobile">management&#64;mrfy.net</div>
                <div class="info-top-mobile">random:</div>
                <div class="info-sub-mobile">info&#64;mrfy.net</div>
                <div class="info-top-mobile">web shop:</div>
                <div class="info-sub-mobile">shop&#64;mrfy.net</div>
            </div>
        </div>

        <div class="info-tour-mobile">Webshop</div>
        <div class="row-info-mobile">
            <div class="column-info-mobile">
                <div class="info-top-mobile"></div>
                <div class="info-sub-mobile link" (click)="this.router.navigateByUrl('company-information')">> Company Information</div>
                <div class="info-sub-mobile link" (click)="this.router.navigateByUrl('return-and-refund')">> Return & Refund</div>
                <div class="info-sub-mobile link" (click)="this.router.navigateByUrl('terms-of-business')">> Terms of Business</div>
                <div class="info-sub-mobile link" (click)="this.router.navigateByUrl('privacy-policy')">> Privacy Policy</div>

            </div>
        </div>


    </div>
</div>
<app-footer-web></app-footer-web>
