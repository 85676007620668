import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NewTourPopup} from "../../AdminPanel/popups/new-tour/NewTourPopup.component";
import {MatDialog} from "@angular/material/dialog";
import {Customer} from "../../Model/CustomerModel";
import {Address} from "../../Model/Address";
import {PreorderPopupComponent} from "../../AdminPanel/popups/preorder-popup/preorder-popup.component";
import {CustomMailService} from "../../Service/CustomMail";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {SubscribeComponent} from "../../AdminPanel/popups/subscribe/subscribe.component";
import {SubscriptionModel} from "../../Model/SubscriptionModel";
import {SubscriptionService} from "../../Service/Subscription.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public popup: MatDialog, private snackBar: MatSnackBar, private customMailSender:CustomMailService, private router:Router, private subscriptionService:SubscriptionService) { }

  ngOnInit(): void {
    const pictureContainer = document.getElementById("front-body") as HTMLElement;
    const pictureElement = document.getElementById('vinyl-moving-image') as HTMLImageElement;
    const picture = new Picture(pictureContainer, pictureElement)
  }

  async openSubscribePopup(): Promise<void>{
    const popup = this.popup.open(SubscribeComponent, {
      width:'450px',
      data: new SubscriptionModel(0, "", "", "")
    });

    popup.afterClosed().subscribe(result=>{
      if(result){
        console.log("New subscriber: ");
        console.log(result);
        let date = new Date();
        result.date_subscribed = date.toISOString();
        this.snackBar.open("Thank you for your subscription to our newsletter!!", "Close");
        this.subscriptionService.subscribe(result);
      }
    })
  }
  async clickHeaderLink(): Promise<void>{
    this.router.navigateByUrl("product/"+environment.preorderItemId);
  }

}

interface Point{
  x: number;
  y: number;
}
class Picture {
  private container: HTMLElement;
  private element: HTMLImageElement;
  private position: Point = { x: 0, y: 0 };
  private velocity: Point = { x: 3, y: 3};
  private mousePosition: Point = { x: -0.5, y: -0.5 };
  private isBouncingOffScreen: boolean = true;

  constructor(container: HTMLElement, element: HTMLImageElement) {
    this.container = container;
    this.element = element;

    this.element.style.position = 'absolute';
    this.container.addEventListener('mousemove', (event: MouseEvent) => {
      this.mousePosition = {
        x: event.clientX - this.container.offsetLeft,
        y: event.clientY - this.container.offsetTop
      };
    });

    requestAnimationFrame(this.move.bind(this));
  }

  private move() {

    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;

    const containerWidth = this.container.clientWidth;
    const containerHeight = this.container.clientHeight;
    const pictureWidth = parseInt(this.element.style.width);
    const pictureHeight = parseInt(this.element.style.height);

    if(this.position.x < 0) this.position.x = 0;
    if(this.position.x+pictureWidth > containerWidth) this.position.x = containerWidth-pictureWidth;
    if(this.position.y < 0) this.position.y = 0;
    if(this.position.y+pictureHeight > containerHeight) this.position.y = containerHeight-pictureHeight;

    if (this.isBouncingOffScreen) {
      if (this.position.x <= 0 || this.position.x + pictureWidth >= containerWidth) {

        this.velocity.x = -this.velocity.x;
      }
      if (this.position.y <= 0 || this.position.y + pictureHeight >= containerHeight) {
        this.velocity.y = -this.velocity.y;
      }
    } else {
      if (this.position.x <= 0) {
        this.position.x = 0;
      }
      if (this.position.x + pictureWidth >= containerWidth) {
        this.position.x = containerWidth - pictureWidth;
      }
      if (this.position.y <= 0) {
        this.position.y = 0;
      }
      if (this.position.y + pictureHeight >= containerHeight) {
        this.position.y = containerHeight - pictureHeight;
      }
    }

    if (this.mousePosition.x !== -1 && this.mousePosition.y !== -1) {
      const distanceX = this.position.x + pictureWidth / 2 - this.mousePosition.x;
      const distanceY = this.position.y + pictureHeight / 2 - this.mousePosition.y;
      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
      const minDistance = pictureWidth / 2; // 20 is the cursor distance threshold

      if (distance < minDistance) {
        const angle = Math.atan2(distanceY, distanceX);
        console.log(Math.cos(angle) * minDistance);
        console.log("Cos: " + Math.cos(angle));
        console.log("Cos: " + Math.sin(angle));
        this.velocity.x = Math.cos(angle) * minDistance / 20;
        this.velocity.y = Math.sin(angle) * minDistance / 20;
        console.log("Velocity X: " + this.velocity.x + " Y: " + this.velocity.y)
      }
    }

    this.element.style.left = `${this.position.x}px`;
    this.element.style.top = `${this.position.y}px`;

    requestAnimationFrame(this.move.bind(this));
  }
}
