<footer>
    <!-- SOCIALS MOBILE -->
    <div class="socials">
        <div class="row-socials">
            <div class="col-socials">
                <a href="https://www.facebook.com/MRFYnm/" target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-01.svg"></a></div>
            <div class="col-socials">
                <a href="https://www.instagram.com/mrfy_band/" target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-02.svg"></a></div>
            <div class="col-socials">
                <a href="https://www.tiktok.com/@mrfyisthenew"  target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-03.svg"></a></div>
            <div class="col-socials">
                <a href="https://music.apple.com/si/artist/mrfy/1193215834" target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-04.svg"></a></div>
            <div class="col-socials">
                <a href="https://open.spotify.com/artist/0nWEe4MRacRVVWT71b9PeM" target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-05.svg"></a></div>
            <div class="col-socials">
                <a href="https://www.youtube.com/channel/UCfBQmBpRjEsIBUD9KbxxxQQ" target="_blank"><img class="socials-mobile" src="assets/img/SOCIAL_LOGO-06.svg"></a></div>
        </div>
    </div>
</footer>
<script src="assets/js/main.js"></script>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-OERcA2EqjJCMA+/3y+gxIOqMEjwtxJY7qPCqsdltbNJuaOe923+mo//f6V8Qbsw3" crossorigin="anonymous"></script>
<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
