<!-- BODY -->
<app-header></app-header>
<div class="container-rr">
    <div class="row-rr">
        <h1 class="title-rr">Return & Refund</h1>
        <div class="text-rr">When purchasing goods or services online or outside of a store (by phone, catalog sales, home sales representative), the buyer has the right to withdraw from the contract, cancel the order for any reason and without explanation within 14 days. A written statement of withdrawal from the contract can be submitted in the following ways:<br>

            by email to<a href="mailto:shop@mrfy.net"> shop&#64;mrfy.net</a><br><br>

            The deadline for return begins to run on the day after the consumer receives the goods. The cost that the buyer incurs when withdrawing from the contract is the cost of returning the goods. The buyer must return the goods to the provider immediately after withdrawal, but no later than fourteen (14) days after withdrawing from the contract. Simply returning the goods within the withdrawal period without an accompanying statement of withdrawal is not considered a withdrawal from the contract.<br><br>

            The buyer must return the goods to the provider undamaged, in the same quantity, and in the original packaging. The buyer must attach a copy of the invoice and details of the transaction account to the statement, to which the provider will return the funds. The refund will be made no later than fourteen (14) days after receiving the notice of withdrawal from the contract. The provisions of the Obligations Code and the Consumer Protection Act apply in this regard.<br><br>
            The seller has the right to withhold the refund until the returned goods are received or until proof is provided that the consumer has sent the product to the seller.<br><br>

            The possibility of withdrawing from the contract or returning goods does not apply in the following cases:<br>
            <ul>
                <li>for goods that are not in their original packaging or have had the security seal removed and indicate use of the product.</li>
                <li>for goods that have been made to measure for the customer or have been adapted to their personal needs,</li>
                <li>for goods that are not suitable for return due to their nature,</li>
                <li>the buyer is responsible for any reduction in the value of the goods if the reduction is due to handling that is not necessary to establish the nature, characteristics, and functioning of the goods.</li>
            </ul>
        </div>
    </div>
</div>
<app-footer-web></app-footer-web>
