export const environment = {
  production: true,
  apiHost: "https://api.mrfy.net",
  apiPath: "/api/v1/",
  paymentGatewayAPIPath: "/payment-gateway/",
  base: "/",
  stripe_key: "pk_live_51MlSwFBMqEIHC67EF0fDVsRw8MOAlkjfqKYDiYL5bgyYwgnqfwbMJiaAjHVHILll7mQnA5nykJGCmRMCCXz4ieBF00WkJv4Kc9",
  cookieHost: "mrfy.net",
  preorderItemId: 13,
  webshopEnabled: true,
  shipmentPrices: [{
      minWeight: 1,
      maxWeight: 2000,
      price: 4.63
    },
    {
      minWeight: 2001,
      maxWeight: 5000,
      price: 5.79
    },
    {
      minWeight: 5001,
      maxWeight: 10000,
      price: 7.06
    }
  ]
};
