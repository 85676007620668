import {Address} from "./Address";

export class Customer {
    public id: number;
    public name: string;
    public gender: number;
    public email: string;
    public phone: string;
    public address: Address;

    constructor(id: number,
                name: string,
                gender: number,
                email: string,
                phone: string,
                address: Address,
    ) {
        this.id = id;
        this.name = name;
        this.gender = gender;
        this.email = email;
        this.phone = phone;
        this.address = address;
    }
}
