import {Employee} from "../Model/EmployeeModel";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { environment } from '../../environments/environment';
import {catchError, throwError} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class EmployeeService {

    constructor(private HttpClient: HttpClient) {
    }

    async registerUser(employee:Employee){
        return new Promise((resolve, reject)=>{
            this.HttpClient.post<any>(environment.apiHost+environment.apiPath+"register", employee)
                .pipe(
                    catchError((err:HttpErrorResponse)=>{
                        console.log("Error: " + err.error);
                        if(err.status == 400) return throwError("An user with email address " + employee.email + " already exists!");
                        return throwError("Unknown error");
                    })
                )
                .subscribe({
                    next: (response)=>{
                        resolve(response);
                    },
                    error: (error)=>{
                        reject(error);
                    }
                });
        })
    }

    async listEmployees(page=1, filter={}, itemsPerPage=25)
    {
        return new Promise(resolve=>{
            this.HttpClient.get<any>(environment.apiHost+environment.apiPath+"employees?filter="+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        });
    }
    async editEmployee(employee:Employee){
        return new Promise(resolve=>{
            this.HttpClient.put<any>(environment.apiHost+environment.apiPath+"employee/"+employee.id, employee, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        });
    }
    getHttpOptions(){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return httpOptions;
    }
}
