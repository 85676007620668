import {Customer} from "../Model/CustomerModel";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class CustomMailService{

    constructor(private HttpClient: HttpClient) {
    }


    async sendPreorderMail(customer:Customer){
        return new Promise(resolve=>{
            this.HttpClient.post<any>(environment.apiHost+environment.apiPath+"preorder",customer)
                .subscribe(response=>{
                    resolve(response);
                })
        });
    }
}
