<app-header></app-header>
<div class="container-tob">
    <div class="row-tob">
        <h1 class="title-tob">Terms of Business</h1>
        <div class="text-tob">The general terms and conditions of the MRFY online store are composed in accordance with the applicable regulations of the Republic of Slovenia and apply to all users of the online store. The general terms and conditions determine the conditions for using the M&M Intercom online store, general purchase conditions through the online store, protection of personal data, as well as other rights and obligations of the user and the provider. The terms and conditions of business can be supplemented with new provisions at any time, therefore, the buyer is advised to read them with every purchase.<br><br>

        The operator of the online store is the company Prjatučki d.o.o., Ob Težki vodi 44A, 8000 Novo Mesto, Slovenia, tax number: SI59787066, registration number: 9310487000, registered with the District Court in Novo Mesto (hereinafter referred to as the provider).<br><br>

        For all information related to the online store, we are available at the email address <a href="mailto:shop@mrfy.net">shop&#64;mrfy.net</a>.<br><br>

        By registering or using the online store of the company Prjatučki d.o.o., the user accepts these general terms and conditions of business and confirms that they are familiar with them and fully agree with them.<br><br>

        </div><h2 class="subtitle-tob">1. Purpose</h2><div class="text-tob">
            The purpose of the written general terms and conditions of business is to clearly define the sales conditions between the provider (Prjatučki d.o.o.) and the customer in the online store. The term customer refers to any physical or legal person who has made a purchase through the MRFY online store. The MRFY online store is intended for individuals and business users. The online store can be used without prior registration in the system. Business users register in the system through a form, thereby obtaining a username and password that clearly identify and link them with all data.
        <br></div><h2 class="subtitle-tob">2. User data entry</h2><div class="text-tob">
            The user does not register or log in to the online store, but their data is stored only in the event of an order being placed.
        <br></div><h2 class="subtitle-tob">3. Prices and goods</h2><div class="text-tob">
            All prices listed are in euros and include VAT. Prjatučki d.o.o. reserves the right to change the prices of the offered goods without prior notice. Prices apply at the time of placing an order for items that are in stock. For items that are not in stock, Prjatučki d.o.o. reserves the right to change the price. If the price displayed in the online store is incorrect or the price of the offered goods changes during order processing, the provider allows the buyer to withdraw from the purchase. The shipping cost is not included in the price of the products. Therefore, the prices do not include delivery costs. Delivery costs are stated when calculating the order value and onwards on the invoice. The provider reserves the right to change technical descriptions and configurations without prior notice. The images are only symbolic and do not always reflect the actual state or properties of the product. The validity periods of the offer are stated in the promotional offer.
        <br></div><h2 class="subtitle-tob">4. Order</h2><div class="text-tob">
            Both physical and legal entities can purchase products at the MRFY online store. The user selects the desired product by clicking the "Add to Cart" button. The user can remove selected items from the cart or increase the purchase quantity. When the user is satisfied with the desired cart value, they agree to the terms of service. If the user does not agree to the general terms and conditions of the transaction, they cannot proceed. After completing the purchase, the user enters their personal information, the delivery address, and the email address where the buyer will be informed about the shipment. The user then selects the payment method, which includes payment by credit card or multiple electronic wallets (e.g., Apple Pay, Google Pay...). If the user does not agree to the general terms and conditions of the transaction, they cannot proceed with the order. The user then receives a notice that the order has been successfully placed. The user receives confirmation of the order via the email address provided to the provider. Upon receiving the ordered products, the user also receives information about the order in writing.
        <br></div><h2 class="subtitle-tob">5. Purchase Security</h2><div class="text-tob">
            The provider uses an SSL certificate for the transfer of all data from online purchases, which encrypts all information sent with user orders. The user is solely responsible for accessing their user account and will also take care of protecting user data and passwords. The user guarantees the accuracy and truthfulness of all transmitted data and is responsible for any damage caused by inaccurate or untrue data.
        <br></div><h2 class="subtitle-tob">6. Accessibility of Information</h2><div class="text-tob">
            The provider undertakes to always provide the user with access to the following information:
            <ul><li>
                <li>company information (company name, address, tax, registration number),</li>
                <li>contact information,</li>
                <li>characteristics of goods or services,</li>
                <li>price of goods or services, including VAT,</li>
                <li>delivery costs, if included.</li></ul>
        <br></div><h2 class="subtitle-tob">7. Payment</h2><div class="text-tob">
            The user can choose between payment by credit card (e.g., Visa, MasterCard, AmericanExpress...) or multiple digital wallets (Apple Pay, Google Pay...).
        <br></div><h2 class="subtitle-tob">8. Delivery and Delivery Timeframe</h2><div class="text-tob">
            Products that are in stock will be delivered within 30 working days from the day of final order confirmation.
        <br></div><h2 class="subtitle-tob">9. Warranty</h2><div class="text-tob">
            The provider guarantees products in accordance with the legislation of the Republic of Slovenia.
        </div> <h2 class = "subtitle-tob"> 10. Complaint </h2> <div class = "text-tob">
            The buyer has the right to inform the provider in writing within fourteen (14) days of receiving the goods that they are withdrawing from the contract, without having to give a reason for their decision. The consumer can give their withdrawal by an unambiguous statement, which clearly shows the reason for withdrawing from the contract, either by e-mail to the address <a href = "mailto: shop@mrfy.net"> shop&#64;mrfy.net </a> or to the company's address. The deadline for return starts running the day after the consumer receives the goods. The cost that the buyer has when withdrawing from the contract is the cost of returning the goods. The buyer must return the goods to the provider immediately after withdrawing, but no later than fourteen (14) days after withdrawing from the contract. Simply returning the goods within the withdrawal period without an accompanying statement of withdrawal is not considered a withdrawal from the contract. The buyer must return the goods to the provider undamaged, in the same quantity, and in the original packaging. The buyer must attach a copy of the invoice and information about the transaction account to the statement so that the provider can return the funds. The return will be made no later than fourteen (14) days after receiving the notice of withdrawal from the contract. The provisions of the Obligations Code and the Consumer Protection Act apply in this regard.
        </div> <h2 class = "subtitle-tob"> 11. Personal Data Protection </h2> <div class = "text-tob">
            By registering in the MRFY online store, the user allows the company Prjatučki d.o.o. as the data controller of personal data provided by the user to use and process personal data in accordance with the provisions of the Personal Data Protection Act and for the purpose determined below.
            The controller processes the user's personal data, which the user entered in the registration form (individual: name, surname, address, postal code, place, phone or GSM - optional, email); legal entity: name, surname, phone or GSM, tax number, email, password). The following data is also collected: information about purchases and benefits calculated: date of purchase, type of purchase, content of purchase, purchase value, payment method. The provider will use personal data for the purpose of fulfilling the order (performing the transaction, issuing an invoice, delivering the product, everything related to the order) and other communication related to the order. If there are any questions regarding the protection of personal data, the user can send them via email to the address <a href = "mailto: shop@mrfy.net"> shop&#64;mrfy.net </a>.<br><br>
            The provider undertakes to use and store all personal information obtained from the customer, as well as information about purchases and customer habits, in accordance with the provisions of the Personal Data Protection Act. The provider directly collects information that customers provide when placing an order. The provider does not disclose this information to third parties, or will disclose it to third parties only if the conditions of the Personal Data Protection Act are met. The websites for ordering products and transfers are protected and appropriately technically or electronically secured. The customer is also responsible for protecting their personal data by ensuring the security of their username and password and appropriate software (antivirus) protection of their information system or electronic device.

            Cookies are used in the online store:

            <ul>
                <li>Technical cookies - these cookies are necessary for the proper functioning of the M&M Intercom website and include:
                    Session cookies, which are used to remember the user's entries in web forms, shopping cart, etc. Such cookies are essential for the site to remember and execute the user's request.</li>
                <li>Cookies for traffic analysis purposes - analytical cookies - (counting visitors, identifying browsers and keywords leading to the site, navigation problems)
                    Google Analytics cookies are used to track visits to the MRFY online store, track sales, and track other user habits that are displayed on the MRFY online store.</li>
            </ul>
            The contract is concluded when the provider confirms receipt of the order, namely with an electronic message sent to the provided email address. The contract is concluded in Slovenian. The concluded contract between the user and the seller, as well as copies of invoices, are archived at the headquarters of Prjatučki d.o.o. The user can obtain a copy by requesting it by email at: <a href="mailto:shop@mrfy.net">shop&#64;mrfy.net</a>.
        <br></div><h2 class="subtitle-tob">14. Final provisions</h2><div class="text-tob">
            The provider reserves the right to change the general terms and conditions of business at any time without prior notice. The provider will inform users of any changes to the general terms and conditions of business in an appropriate manner. Posting the general terms and conditions of business on the website mrfy.net is considered an appropriate way. It is considered that the user agrees to the changes in the general terms and conditions of business if they continue to use the portal in question. The provider makes every effort to ensure the accuracy and timeliness of the information published on its pages. However, the properties of the items, delivery times, or prices may change so quickly that the provider is unable to correct the information on the website. In such a case, the provider will inform the user of the changes and enable them to withdraw from the order or replace the ordered item. Objections, comments, claims, statements, and complaints can be sent by the user via email to shop&#64;mrfy.net. The resolution of objections, comments, claims, and complaints will be carried out in accordance with the provisions of the applicable legislation. The competent court for all disputes arising from the use of the online store and these general terms and conditions of business that the provider and the user could not resolve by mutual agreement is the court in Novo Mesto.
            In accordance with legal norms, we do not recognize any out-of-court dispute resolution provider as competent for resolving consumer disputes that the consumer could initiate in accordance with the Consumer Dispute Resolution Act. Prjatučki d.o.o., which as a provider of goods and services enables an online store in Slovenia, publishes an electronic link to the platform for online resolution of consumer disputes (SRPS) on its website. The platform is available to consumers at the following link: <a href="https://www.gov.si/teme/resevanje-potrosniskih-sporov/">HERE</a>.

            <br><br>The above conditions will take effect on March 15, 2023.<br><br>

            Novo Mesto, March 14, 2023<br>
            Prjatučki d.o.o.
        </div>
    </div>
</div>
<app-footer-web></app-footer-web>
