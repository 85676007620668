import { Component, OnInit } from '@angular/core';
import {trim} from "jquery";
import {AuthService} from "../../shared/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService:AuthService) { }

  public error:string="";
  public username:string="";
  public password:string ="";
  ngOnInit(): void {
  }

  public async login() {
    if (this.username.trim().length > 0 && this.password.trim().length > 0) {
      let loginOutput = await this.authService.login(this.username, this.password);
      if(loginOutput.status == 401)
      {
        this.error="Your username or password is not matching. Please try again!"
      }
    }
  }

  public setPassword(event:any)
  {
    this.password = event.target.value;
  }

  public setUsername(event:any)
  {
    this.username = event.target.value;
  }
}
