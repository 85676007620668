import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Product} from "../Model/ProductModel";
import {environment} from "../../environments/environment";

@Injectable()
export class StripeService {
    HttpClient:HttpClient;

    constructor(HttpClient: HttpClient) {
        this.HttpClient = HttpClient;
    }

    async getCheckoutSession(id:any){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{

            this.HttpClient.get<any>(environment.apiHost+environment.paymentGatewayAPIPath+"checkout_sessions/"+id, httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }
    async getPaymentIntent(id:any){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve=>{

            this.HttpClient.get<any>(environment.apiHost+environment.paymentGatewayAPIPath+"payment_intents/"+id, httpOptions)
                .subscribe(response=>{
                    resolve(response)
                })
        });
    }
    getHttpOptions(){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return httpOptions;
    }
}
