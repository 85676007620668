import {PaymentModel} from "../Model/PaymentModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class PaymentService{

	constructor(private HttpClient: HttpClient){}
	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}
	async createNewPayment(payment:PaymentModel){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.post(environment.apiHost+environment.apiPath+"payment", payment, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async getPayments(page=1, filter={},  itemsPerPage=25, sorting={}){
		const httpOptions = this.getHttpOptions();
		return new Promise((resolve, reject) => {
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'payment?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async getPaymentByOrderId(orderId:number, page=1, filter={},  itemsPerPage=25, sorting={}){
		return new Promise(resolve=>{
			this.getPayments(1, {order_id: orderId}, 1)
				.then((response:any) =>{
					resolve(response.result[0]);
				})
		})

	}
}
