import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {SubscriptionModel} from "../Model/SubscriptionModel";

@Injectable({
    providedIn: "root"
})
export class SubscriptionService{
    constructor(private HttpClient: HttpClient){}
    getHttpOptions(){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return httpOptions;
    }
    async subscribe(subscription:SubscriptionModel){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.post<SubscriptionModel>(environment.apiHost+environment.apiPath+"subscription", subscription, httpOptions)
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }
    async unsubscribe(subscriberId:number){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.delete<SubscriptionModel>(environment.apiHost+environment.apiPath+"subscription/"+subscriberId, httpOptions)
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }

    async sendMessage(message:string, subject:string){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.post<any>(environment.apiHost+environment.apiPath+"subscription/send-mail",{content: message, subject:subject}, httpOptions)
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }

}
