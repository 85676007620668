import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SubscriptionModel} from "../../../Model/SubscriptionModel";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent {

  constructor(
      public dialogRef: MatDialogRef<SubscribeComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SubscriptionModel) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
