<app-header></app-header>
<!-- BODY -->

<div class="container-tour">
    <div class="row-tour">
        <!--<h1 class="tour-title">TOUR DATES</h1>-->
        <div *ngFor="let tour of nextTours" class="tour-item">
            <p class="tour-dates">{{tour.date | date: "dd. MM. yyyy" }}</p>
            <p class="tour-location">{{tour.place}}</p>
            <a href="{{tour.tickets_link}}" *ngIf="!tour.sold_out" target="_blank"><img src="assets/img/tickets.svg" class="tour-img"></a>
            <img *ngIf="tour.sold_out" src="assets/img/soldout.svg" class="tour-img past">
        </div>
        <!--<h1 class="tour-title past">PAST TOUR DATES</h1>-->
        <div *ngFor="let tour of pastTours" class="tour-item">
            <p class="tour-dates past">{{tour.date | date: "dd. MM. yyyy" }}</p>
            <p class="tour-location past">{{tour.place}}</p>
            <!--<img src="assets/img/soldout.svg" class="tour-img past">-->
        </div>
    </div>
</div>




    <app-footer-web></app-footer-web>
