<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Lastname</mat-label>
        <input matInput type="text" [(ngModel)]="data.lastname">
    </mat-form-field>
    <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput type="text" [(ngModel)]="data.email">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button class="btn btn-primary" mat-button (click)="onNoClick()">Prekliči</button>
    <button class="btn btn-primary" mat-button [mat-dialog-close]="data" cdkFocusInitial>Prijavi se</button>
</div>
