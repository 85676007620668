<!-- HEADER -->
<app-header></app-header>
<!-- BODY -->
<div class="form-container">
    <div style="max-width:50%">
        <div class="row-form">
            <div class="form-title">Registracija</div>
        </div>
        <div class="row-form">
            <mat-form-field class="form-w50-l">
                <mat-label>Ime</mat-label>
                <input matInput [(ngModel)]="employee.name">
            </mat-form-field>
            <mat-form-field class="form-w50-r">
                <mat-label>Priimek</mat-label>
                <input matInput [(ngModel)]="employee.lastname">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>E-Mail</mat-label>
                <input matInput [(ngModel)]="employee.email">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>Telefon</mat-label>
                <input matInput [(ngModel)]="employee.phone">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>Geslo</mat-label>
                <input matInput type="password" [(ngModel)]="employee.password">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Birthdate</mat-label>
                <input matInput [matDatepicker]="datePicker" placeholder="Date and Time" [(ngModel)]="employee.birthdate">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <h1>Naslov</h1>
        <div class="row-form">
            <mat-form-field  class="form-w100">
                <mat-label>Ulica in Hišna številka</mat-label>
                <input matInput [(ngModel)]="employee.address.addressLine1">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>Stanovanje/Stavba</mat-label>
                <input matInput [(ngModel)]="employee.address.addressLine2" >
            </mat-form-field>
            <mat-form-field class="form-w20-l">
                <mat-label>Poštna Številka</mat-label>
                <input matInput [(ngModel)]="employee.address.postalCode">
            </mat-form-field>
            <mat-form-field class="form-w80-r">
                <mat-label>Kraj</mat-label>
                <input matInput [(ngModel)]="employee.address.city">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>Regija</mat-label>
                <input matInput [(ngModel)]="employee.address.region">
            </mat-form-field>
            <mat-form-field class="form-w100">
                <mat-label>Država</mat-label>
                <input matInput [(ngModel)]="employee.address.country">
            </mat-form-field>
        </div>

        <div class="row-form">
            <button type="submit" class="login-btn" (click)="register()">Register</button></div>
        <div class="row-form" >
            <div class="form">Already an user? <a class="form-links" href="/login">Click here</a></div>
        </div>
    </div>
</div>

<app-footer-web></app-footer-web>

