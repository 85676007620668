<app-header></app-header>
<div class="container-pp">
    <div class="row-pp">
        <h1 class="title-pp">Privacy Policy</h1>
        <h2 class="subtitle-pp">Privacy</h2>
        <div class="text-pp">
            By registering in the MRFY online store, the user allows the company Prjatučki d.o.o., Ob Težki vodi 44A, 8000 Novo Mesto, as the controller of the collection of personal data, to use and process the provided personal data in accordance with the provisions of the Personal Data Protection Act and for the purpose specified below. <br> <br>
            The controller processes the user's personal data that the user entered in the registration form (natural person: name, surname, address, postal code, city, phone or mobile phone - optional, date of birth - optional, email address, password); legal person: name, surname, phone or mobile phone, tax number, email address - if personal data arise from it, password, repeat password). <br> <br>

            The following data are also collected: data on purchases and accrued benefits: date of purchase, type of purchase, content of purchase, purchase value, payment method. <br> <br>

            The provider will use personal data for the purpose of fulfilling the order (performing the transaction, issuing an invoice, delivering the product, and all communication related to the order) and other communication related to the order. <br> <br>

            If the user has any questions regarding the protection of personal data, they can send them to the address Ob Težki vodi 44A, 8000 Novo Mesto or by e-mail to <a href="mailto:shop@mrfy.net">shop&#64;mrfy.net</a>.<br><br>

            The provider undertakes to use and store all acquired personal data of the customer and data on their purchasing habits in accordance with the provisions of the Personal Data Protection Act.<br><br>

            The provider directly collects information that customers provide when placing an order. The provider does not disclose this information to third parties, or will disclose it to third parties only when the conditions specified in the Personal Data Protection Act have been met. Websites for ordering products and transfers are protected and appropriately technically or electronically secured. The customer is also responsible for protecting their personal data, by ensuring the security of their username and password and appropriate software (antivirus) protection of their information system or electronic device.<br><br>

        </div>
        <h2 class="subtitle-pp">General terms and conditions for subscribing to and receiving e-newsletters</h2>
        <div class="text-pp">Prjatučki d.o.o., Ob Težki vodi 44A, 8000 Novo Mesto, sets out the obligations, rights, and conditions for the use and management of personal data of registered users in the general terms and conditions for subscribing to and receiving e-newsletters.<br><br>

            Physical or legal persons who sign up to receive e-newsletters on the website mrfy.net and provide their e-mail address when registering can subscribe to e-newsletters.<br><br>

            Prjatučki d.o.o., Ob Težki vodi 44A, 8000 Novo Mesto, respects your privacy and undertakes to protect the data obtained with the registration form on the website mrfy.net in accordance with the applicable legislation governing the protection of personal data (ZVOP-1-UPB1).<br><br>

            By signing up for the newsletter of the company Prjatučki d.o.o.:
            <ul><li>I agree with the general terms of registration, collection, storage, receiving, and processing of personal data; any changes will be updated on the M&M Intercom website.</li>
                <li>I allow Prjatučki d.o.o. to establish, collect, maintain, use, process, and store my data in their contact databases, as well as other information about me as a newsletter user, in the manner and for the purposes described below;</li>

                <li>I declare that I am aware that I can request at any time that Prjatučki d.o.o. stop using my personal data for direct marketing and sending informational materials.</li></ul>

            <br>The purposes of collecting, storing, and processing user data are:
            <ul><li>sending technical news,</li>
                <li>sending promotional offers,</li>
                <li>sending technical publications,</li>
                <li>professional advice,</li>
                <li>sending video content,</li>
                <li>sending event invitations,</li>
                <li>processing data for the possible execution of a prize game with communication with participants by e-mail,</li>
                <li>processing data for further communication,</li>
                <li>market research.</li></ul>
            <br>The user can unsubscribe from the database by clicking on the unsubscribe link in the received newsletter. The user can request the removal of their contact and newsletter subscription by sending a request to shop&#64;mrfy.net. After confirming the unsubscribe request, all data will be removed from the user list.<br><br>

            Personal data is stored in electronic form. After the need for personal data storage ends or upon written revocation by the user, the data will be deleted or destroyed.<br><br>

            Prjatučki d.o.o. commits to not under any circumstances disclose personal or other data to third parties without explicit permission from the user, or allow third parties access to personal or other data of the participant or user, except if required by law enforcement authorities, if such obligation is determined by law or for the purposes of proceedings before courts or other state authorities.<br><br>
        </div>
    </div>
</div>
<app-footer-web></app-footer-web>
