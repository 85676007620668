import { Component, OnInit } from '@angular/core';
import {SubscriptionService} from "../../Service/Subscription.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  public waiting: boolean = true;
  public failed: boolean = false;
  constructor(private route: ActivatedRoute, public subscriptionService:SubscriptionService) { }

  async ngOnInit(): Promise<void> {
    try {
      this.waiting = true;
      this.failed = false;
      let id = Number(this.route.snapshot.paramMap.get('id'));
      await this.subscriptionService.unsubscribe(id);
      this.waiting = false;
    }catch(e){
      this.failed = true;
    }
  }



}
