import {Address} from "./Address";
import {Customer} from "./CustomerModel";

export class Order
{
public id:number;
public customer:Customer;
public order_status:number;
public placed_date:Date;
public details:string;
public recipient_address:Address;

constructor(id: number,
            customer: Customer,
            order_status: number,
            placed_date: Date,
            details: string,
            recipient_address:Address
    ){
        this.id = id
        this.customer = customer
        this.order_status = order_status
        this.placed_date = placed_date
        this.details = details
        this.recipient_address = recipient_address
    }}
