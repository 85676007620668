import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Tour} from "../../Model/TourModel";
import {TourService} from "../../Service/Tour.service";

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  //Add All
  public pastTours:Array<Tour> = [];
  public nextTours:Array<Tour> = [];

  constructor(private HttpClient: HttpClient, private tourService:TourService) { }

  async ngOnInit(): Promise<void> {
    await this.setupTours();
  }
  async setupTours(){
    let pastToursResult:any = await this.tourService.getPastTours(1,99999999)
    this.pastTours = <Array<Tour>> pastToursResult.result;
    console.log(this.pastTours);

    let incomingToursResult:any = await this.tourService.getIncomingTours(1,999999999)
    this.nextTours =<Array<Tour>> incomingToursResult.result;
    console.log("Incomming: " + this.nextTours);
  }

}
