import {Component, OnInit} from '@angular/core';
import {StripeService} from "../../Service/Stripe.service";
import {CartService} from "../../Service/CartService.service";
import {Customer} from "../../Model/CustomerModel";
import {Address} from "../../Model/Address";
import {Order} from "../../Model/OrderModel";
import {PaymentM} from "../../Model/Payment.model";
import {OrderService} from "../../Service/Order.service";


@Component({
    selector: 'app-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

    constructor(private stripeService: StripeService, private cartService: CartService, private orderService:OrderService) {}

    async ngOnInit(): Promise<void> {
        let paymentSession = localStorage.getItem("stripeSession");

        //PAID BY STRIPE
        /*if (paymentSession != null && this.cartService.getItems().length > 0) {
            let checkoutSession: any = await this.stripeService.getCheckoutSession(paymentSession);
            let paymentIntent: any = await this.stripeService.getPaymentIntent(checkoutSession?.payment_intent)


            try {
                console.log("Starting")
                let providedCustomer = new Customer(0, checkoutSession.customer_details.name, 0, checkoutSession.customer_details.email, checkoutSession.customer_details.phone, this.retrieveCustomerAddress(checkoutSession));
                console.log("Order: ")
                let newOrder = new Order(0, providedCustomer, 4, new Date(), "", this.retrieveOrderAddress(paymentIntent)); //Order status 1 - placed
                console.log(newOrder);
                console.log("Payment: ");

                let payment = new PaymentM(0, newOrder, new Date(), paymentIntent.amount/100, paymentIntent.latest_charge);

                console.log("Placing order: ");
                let createdSuccess = await this.orderService.newOrderPlaced(providedCustomer, newOrder, this.cartService.getItems(), payment);

                localStorage.removeItem("mrfyWebCart");
                localStorage.removeItem("stripeSession");

            } catch (e) {
                console.error(e);
            }


        }*/

    }

    retrieveCustomerAddress(checkout_session:any){
        //console.log("RetrCustAddr")
        //console.log(checkout_session)
        let customer_details = checkout_session.customer_details;
        //console.log("RetrCustAddr")
        //console.log(customer_details);
        return new Address(customer_details.address.city, customer_details.address.state, customer_details.address.country, customer_details.address.line1, customer_details.address.line2, customer_details.address.postal_code);
    }

    retrieveOrderAddress(paymentIntent:any){
        let shipping_details = paymentIntent.shipping;
        return new Address(shipping_details.address.city, shipping_details.address.state, shipping_details.address.country, shipping_details.address.line1, shipping_details.address.line2, shipping_details.address.postal_code);
    }


}
