<div class="logo">
    <a href="#" class="simple-text">
        <div class="logo-img">
            <img src="/assets/img/mrfy.png" style="width:75%; height:45px; margin:auto"/>
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search..." style="display:none">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
        </form>
    </div>
    <ul class="nav">
        <ng-container *ngFor="let menuItem of menuItems" [ngTemplateOutlet]="menuItem.children.length <= 0 ? menuItemWithoutChild : menuItemWithChild" [ngTemplateOutletContext]="{menuItem: menuItem, level: 0}"></ng-container>
        <li class="nav-item nav-with-children">
            <a class="nav-link" (click)="logout()" style="margin: 10px 10px 0px {{(0*45)+10}}px">
                <i class="material-icons">lock</i>
                <p>Odjava</p>
            </a>
        </li>
    </ul>
</div>


<ng-template #menuItemWithChild let-menuItem="menuItem" let-level="level">
    <li routerLinkActive="active" class="{{menuItem.class}} nav-item nav-with-children">
        <a class="nav-link" (click)="openClose(menuItem)" style="margin: 10px 10px 0px {{(level*45)+10}}px">
            <i class="material-icons">{{menuItem.icon}}</i>
            <p>{{menuItem.title}}</p>
        </a>
        <ul *ngIf="menuItem.opened" style="margin-bottom: 30px;">
            <ng-container *ngFor="let menuItemChild of menuItem.children" [ngTemplateOutlet]="menuItemChild.children.length <= 0 ? menuItemWithoutChild : menuItemWithChild" [ngTemplateOutletContext]="{menuItem: menuItemChild, level: level+1}"></ng-container>
        </ul>
    </li>
</ng-template>

<ng-template #menuItemWithoutChild let-menuItem="menuItem" let-level="level" let-margin="level*5+10">
    <li routerLinkActive="active" class="{{menuItem.class}} nav-item">
        <a  class="nav-link" [routerLink]="[menuItem.path]" style="margin: 10px 10px 0px {{(level*20)+10}}px">
            <i class="material-icons">{{menuItem.icon}}</i>
            <p>{{menuItem.title}}</p>
        </a>
    </li>
</ng-template>

