export class Address {
    public city: string;
    public region: string;
    public country: string;
    public addressLine1: string;
    public addressLine2: string;
    public postalCode: string;

    constructor(city: string,
                region: string,
                country: string,
                addressLine1: string,
                addressLine2: string,
                postalCode: string
    ) {
        this.city = city
        this.region = region
        this.country = country
        this.addressLine1 = addressLine1
        this.addressLine2 = addressLine2
        this.postalCode = postalCode
    }
}
