import {Address} from "./Address";

export class Employee{
    public id:number;
    public name:string;
    public lastname:string;
    public address:Address;
    public email:string;
    public phone:string;
    public password:string;
    public birthdate:Date;
    public approved:Boolean;

    constructor(id:number, name:string, lastname:string, address:Address, email:string, phone:string, password:string, birthdate:Date, approved:Boolean){
        this.id=id;
        this.name=name;
        this.lastname=lastname;
        this.address=address;
        this.email=email;
        this.phone=phone;
        this.password=password;
        this.birthdate=birthdate;
        this.approved=approved;
    }
}
