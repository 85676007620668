<app-header></app-header>



<div class="card">
    <div class="row">
        <div class="col-md-8 cart">
            <div class="cart-title">
                <div class="row">
                    <div class="col" style="color: black;"><h4><b>Shopping Cart</b></h4></div>
                </div>
            </div>
            <div class="row cart-item" *ngFor="let cartItem of cartItems">
                <div class="row main align-items-center">
                    <div class="col-2"><img class="img-fluid" src="assets/uploads/{{cartItem.headliner_image[0]}}"></div>
                    <div class="col"><div class="row">{{cartItem.product_name}}</div></div>
                    <div class="col">
                        <mat-form-field class="quantity-in-cart" [style.width.px]="70" [style.font-size.ox]="18">
                            <input matInput type="number" value="{{cartItem.quantity}}"  min="1" max="{{cartItem.stock}}" (change)="changeCartAmount(cartItem.id, $event)">
                        </mat-form-field>
                    </div>
                    <div class="col"><span class="close" (click)="removeFromCart(cartItem.id)">&#10005;</span>{{cartItem.price}} €</div>

                </div>
                <div class="col cart-item-options">
                    <p>
                        <strong *ngFor="let optionSelected of cartItem.options | keyvalue" style="display:inline">{{optionSelected.key}}: {{optionSelected.value}}, </strong>
                    </p>
                </div>



                <hr class="cart-item-end">
            </div>
            <button class="back-to-merch"><a class="back-to-merch" href="merch" style="border:none">BACK TO MERCH</a></button>
        </div>
        <div class="col-md-4 summary">
            <div><h5><b>Summary</b></h5></div>
            <hr>
            <div class="row">
                <div class="col" style="padding-left:10px;">ITEMS: {{totalProducts}}</div>
                <div class="col text-right">{{cartPrice | number:'1.2-2'}} &euro;</div>
            </div>
            <div class="row">
                <div class="col" style="padding-left:10px;">SHIPPING:</div>
                <div class="col text-right">{{shipmentPrice| number:'1.2-2'}} &euro; </div>
            </div>
            <div class="row">
                <div class="col" style="padding-left:10px;">VAT:</div>
                <div class="col text-right">{{this.vat | number:'1.2-2'}} &euro; </div>
            </div>
            <div class="row" style="border-top: 2px solid grey; padding: 2vh 0;">
                <div class="col">TOTAL PRICE</div>
                <div class="col text-right">{{cartPrice + vat + shipmentPrice | number:'1.2-2' }} &euro;</div>
            </div>
            <mat-checkbox [(ngModel)]="termsAgreement" class="tob-checkbox">I agree to <a href="terms-of-business" target="_blank">terms of business</a>.</mat-checkbox>
            <button class="btn-checkout" [disabled]="!termsAgreement" (click)="onCheckout()">Proceed to payment</button>
        </div>
    </div>

</div>


<app-footer-web></app-footer-web>
