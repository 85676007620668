<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Ime in Priimek</mat-label>
        <input matInput type="text" [(ngModel)]="data.name" required #nameInput="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Spol</mat-label>
        <mat-select [(ngModel)]="data.gender" required #genderSelect="ngModel">
            <mat-option [value]="0">Moški</mat-option>
            <mat-option [value]="1">Ženska</mat-option>
            <mat-option [value]="2">Drugo</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="text" [(ngModel)]="data.email" required #emailInput="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Telefonska številka</mat-label>
        <input matInput type="text" [(ngModel)]="data.phone" required #phoneInput="ngModel">
    </mat-form-field>
    <hr style="width: 100%"/>
    <p>Naslov</p>
    <mat-form-field>
        <mat-label>Ulica in Hišna številka</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.addressLine1" required #addressLine1Input="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Številka Stanovanja/Stavbe</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.addressLine2" required #addressLine2Input="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Poštna Številka</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.postalCode" required #postalCodeInput="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Kraj</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.city" required #cityInput="ngModel">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Regija</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.region" required #regionInput="ngModel">
    </mat-form-field>
    <mat-form-field >
        <mat-label>Država</mat-label>
        <input matInput type="text" [(ngModel)]="data.address.country" required #countryInput="ngModel">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button class="btn btn-primary" (click)="onNoClick()">Prekliči</button>
    <button mat-button class="btn btn-primary" [mat-dialog-close]="data" cdkFocusInitial [disabled]="!(nameInput.valid && genderSelect.valid && emailInput.valid && phoneInput.valid && addressLine1Input.valid && addressLine2Input.valid && postalCodeInput.valid && cityInput.valid && regionInput.valid && countryInput.valid)">Naroči!</button>
</div>
