export class ProductVariation {
    public id:number;
    public product_id:number;
    public variation:any;
    public stock:number;
    public edited:boolean=false;
    public new:boolean=false;

    constructor(id:number, product_id:number, variation:any, stock:number) {
        this.id=id;
        this.product_id=product_id;
        this.variation=variation;
        this.stock=stock;
    }
    public newItem(){
        this.new=true;
        return this;
    }
    public editedItem(){
        if(!this.new) this.edited=true;
        return this;
    }


}