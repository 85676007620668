import {ProductOption} from "./ProductOptionModel";

export class Product {
    public id: number;
    public product_type_code: number;
    public return_authorization_nr: string;
    public name: string;
    public price: number;
    public action_price: number;
    public description: string;
    public options: ProductOption[];
    public headliner_image: string[];
    public general_stock: number;
    public hidden: boolean = false;
    public weight: number;
    public product_last_lowest_price: number;
    public position?: number;


    constructor(
        id: number,
        product_type_code: number,
        return_authorization_nr: string,
        name: string,
        price: number,
        action_price: number,
        description: string,
        options: ProductOption[],
        headliner_image: string[],
        general_stock: number,
        hidden: boolean,
        weight: number,
        product_last_lowest_price: number,
        position?: number
    ) {
        this.id = id;
        this.product_type_code = product_type_code;
        this.return_authorization_nr = return_authorization_nr;
        this.name = name;
        this.price = price;
        this.action_price = action_price;
        this.description = description;
        this.options = options;
        this.headliner_image = headliner_image;
        this.general_stock = general_stock;
        this.hidden = hidden;
        this.weight = weight;
        this.product_last_lowest_price = product_last_lowest_price;
        if(position) this.position = position;
    }
}




