import {Shipment} from "../Model/ShipmentModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ShipmentItem} from "../Model/ShipmentItemModel";
import {Tour} from "../Model/TourModel";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class TourService{

	constructor(private HttpClient: HttpClient){}
	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}
	async createNewTour(tour:Tour){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.post<Tour>(environment.apiHost+environment.apiPath+"tour", tour, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async updateTour(tour:Tour){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.put(environment.apiHost+environment.apiPath+"tour/"+tour.id, tour, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async getAllTours(page=1, filter={},  itemsPerPage=25, sorting={"date":"desc"}){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'tour?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), httpOptions)
				.subscribe((response)=>{
					console.log(response);
					resolve(response);
				})
		})
	}

	async getPastTours(page=1, itemsPerPage=25){
		return new Promise(resolve => {
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'tour?filter={"date":{"lte":"'+new Date().toISOString()+'"}}&size='+itemsPerPage+'&page='+page+'&sorting={"date":"desc"}')
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async getIncomingTours(page=1, itemsPerPage=25, sorting=""){
		return new Promise(resolve => {
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'tour?filter={"date":{"gte":"'+new Date().toISOString()+'"}}&size='+itemsPerPage+'&page='+page+'&sorting={"date":"asc"}')
				.subscribe(response=>{
					resolve(response);
				})
		})
	}


	async deleteTour(tourId:number){
		return new Promise(resolve => {
			this.HttpClient.delete(environment.apiHost+environment.apiPath+'tour/'+tourId)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}


}
