<app-header></app-header>
<div class="front-body" id="front-body" >
  <div class="container-front">

    <div class="main-img" id="vinyl-moving-image" style="width:250px; height:250px;"><img class="cover-img" src="assets/img/mrfy-use-Cover-Art.jpg"></div>
    <!--<img class="title" src="assets/img/vinyl_out_now.svg">-->
    <!--<p class="description-home"></p>-->

    <!--<img class="streaming-icons" src="assets/img/streaming.png" usemap="#streaming">-->
    <div class="streaming-icons">
      <a href="https://open.spotify.com/artist/0nWEe4MRacRVVWT71b9PeM"><img src="assets/img/gumbi-09.svg"></a>
      <a href="https://music.apple.com/si/artist/mrfy/1193215834"><img src="assets/img/gumbi-08.svg"></a>
      <a href="https://www.youtube.com/channel/UCfBQmBpRjEsIBUD9KbxxxQQ"><img src="assets/img/gumbi-10.svg"></a>
      <a href="https://www.deezer.com/en/artist/11668843?autoplay=true"><img src="assets/img/gumbi-12.svg"></a>
      <a href="https://tidal.com/browse/artist/8419780"><img src="assets/img/gumbi-11.svg"></a>
    </div>
    <map name="streaming">
      <area  href="https://open.spotify.com/artist/0nWEe4MRacRVVWT71b9PeM" coords="28,28,26" shape="circle">
      <area  href="https://music.apple.com/si/artist/mrfy/1193215834" coords="90,30,27" shape="circle">
      <area  href="https://www.youtube.com/channel/UCfBQmBpRjEsIBUD9KbxxxQQ" coords="155,28,27" shape="circle">
      <area  href="https://www.deezer.com/en/artist/11668843?autoplay=true" coords="216,28,26" shape="circle">
      <area  href="https://tidal.com/browse/artist/8419780" coords="280,27,26" shape="circle">
    </map>
  </div>




  <img class="subscribe btn-shake" src="assets/img/gumbi-07.svg" (click)="openSubscribePopup()">

</div>

<!-- MOBILE FRONT PAGE-->
<div class="container-mobile">
  <div class="row-mobile mb0">
    <img class="mobile-img" src="assets/img/Vinyl-Transparent3.png">
  </div>
  <div class="row-mobile">
    <!--<div class="front-title" (click)="clickHeaderLink()">MRFY PRAZNIK 2023</div>-->
  </div>
  <div class="row-mobile pb50">
    <div class="front-btn">
      <div class="row-mobile">
        <a href="https://mrfyland.mrfy.net">
          <img class="scale-mobile" src="assets/img/thumbnail_mrfyland_logo.png" />
        </a>
      </div>
      <div class="row-mobile">
        <a href="merch"><img class="scale-mobile" src="assets/img/gumbi-03.svg"></a>
      </div>
      <div class="row-mobile">
        <a href="https://album.link/i/1620553900"><img class="scale-mobile" src="assets/img/gumbi-05.svg"></a>
      </div>
      <div class="row-mobile">
        <a href="tour"><img class="scale-mobile" src="assets/img/gumbi-04.svg"></a>
      </div>
      <div class="row-mobile">
        <a href="info"><img class="scale-mobile" src="assets/img/gumbi-06.svg"></a>
      </div>
      <div class="row-mobile sub-btn">
        <img class="scale-mobile" src="assets/img/gumbi-07.svg" (click)="openSubscribePopup()">
      </div>

    </div>
  </div>

</div>
<app-footer-web></app-footer-web>

