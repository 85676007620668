import {OrderItem} from "../Model/OrderItemModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Customer} from "../Model/CustomerModel";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class OrderItemService{

	constructor(private HttpClient: HttpClient){}
	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}
	async getOrderItems(page=1, filter={},  itemsPerPage=25, sorting={}){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve=>{
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'orderItem?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), httpOptions)
				.subscribe(response=>{
					resolve(response.result);
				})
		});
	}

}
