import {CartItem} from "../Model/CartItemModel";
import {EventEmitter, Injectable, Output} from "@angular/core";
import {Product} from "../Model/ProductModel";
import {ProductVariation} from "../Model/ProductVariation";

@Injectable({
    providedIn: 'root'
})
export class CartService{
    items: CartItem[] = [];
    @Output() cartChanged: EventEmitter<void> = new EventEmitter();

    addToCart(product: Product, variation: ProductVariation, amount:number, details:string){
        this.retrieveItemsFromLocalStorage();

        const newId = (this.items.length > 0) ? this.items[this.items.length-1].id+1 : 0;
        const newItem = new CartItem(newId, product.id, product.name,  0, variation.variation, amount, product.price, details, 1, product.headliner_image, variation.stock, product.weight)
        const matchingObject = this.findMatchingObjectInCart(newItem)

        if(matchingObject >= 0) {this.items[matchingObject].quantity += amount}
        else{this.items.push(new CartItem(newId, product.id, product.name,  0, variation.variation, amount, product.price, details, 1, product.headliner_image, variation.stock, product.weight))}

        localStorage.setItem("mrfyWebCart", JSON.stringify(this.items));
        this.cartChanged.emit();
    }

    findMatchingObjectInCart(cartItem:CartItem){

        var result = this.items.findIndex(item=>{
            if(JSON.stringify(item.options) == JSON.stringify(cartItem.options) && item.product_id == cartItem.product_id) console.log("Found matching cartItem!");
            return (JSON.stringify(item.options) == JSON.stringify(cartItem.options) && item.product_id == cartItem.product_id);
        })
        return result;
    }

    removeFromCart(productIndex:number){
        this.retrieveItemsFromLocalStorage()
        this.items.splice(this.items.findIndex(item => item.id == productIndex), 1);
        localStorage.setItem("mrfyWebCart", JSON.stringify(this.items));
        this.cartChanged.emit();
    }

    clearCart(){
        this.items = [];
        localStorage.setItem("mrfyWebCart", JSON.stringify(this.items));
        this.cartChanged.emit();
    }

    getCartPrice(){
        this.retrieveItemsFromLocalStorage();

        var finalPrice = 0;
        this.items.forEach((item)=>{
            finalPrice += (item.price*item.quantity);
        })

        return finalPrice;
    }

    getCartWeight(){
        this.retrieveItemsFromLocalStorage();
        var finalWeight = 0;
        this.items.forEach((item)=>{
            finalWeight += (item.weight*item.quantity);
        })
        return finalWeight;
    }

    changeItemQuantity(itemId:number, newAmount:number){
        this.retrieveItemsFromLocalStorage();
        for(let item of this.items){
            if(item.id == itemId){
                item.quantity = newAmount;
                break;
            }
        }
        localStorage.setItem("mrfyWebCart", JSON.stringify(this.items));
        this.cartChanged.emit();

    }
    getItems(){
        if(localStorage.getItem("mrfyWebCart") != null) {
            // @ts-ignore
            this.items = JSON.parse(localStorage.getItem("mrfyWebCart")) as CartItem[];
        }
        return this.items;
    }

    retrieveItemsFromLocalStorage(){
        if(localStorage.getItem("mrfyWebCart") != null) {
            // @ts-ignore
            this.items = JSON.parse(localStorage.getItem("mrfyWebCart")) as CartItem[];
        }
    }
}
