import {Shipment} from "../Model/ShipmentModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CartItem} from "../Model/CartItemModel";
import {ShipmentItem} from "../Model/ShipmentItemModel";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class ShipmentService{

	constructor(private HttpClient: HttpClient){}

	async createNewShipment(shipment:Shipment){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.post(environment.apiHost+environment.apiPath+"shipment", shipment, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async createNewShipmentItems(shipmentItem:ShipmentItem | ShipmentItem[]){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.post(environment.apiHost+environment.apiPath+"shipment-item", shipmentItem, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}

	async getShipments(page=1, filter={},  itemsPerPage=25, sorting={}){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'shipment?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), httpOptions)
				.subscribe((response)=>{
					console.log(response);
					resolve(response);
				})
		})
	}
	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}

}
