<app-header></app-header>

<!-- BODY -->

<div class="container-product">
    <div class="row-product product-card" style="background-color:white; color: black; ">

        <div class="row close-btn-row" style="height:5px; width:100%; justify-content:left">
            <button class="close-btn" [routerLink]="'/merch'"><span class="material-icons" style="font-size:80px;">close</span></button>
        </div>
        <div class="column-product-img">
            <div class="side_view" #side_view>
              @for (file of files; track file){
                <img src="{{filePath}}{{file}}" (click)="changeImage(0)" #side_view_img alt="">
              }

            </div>
            <div class="main_view" >
                <img class="product-image" src="{{filePath}}{{files[fileCounter]}}" #main_view alt="">
            </div>

            <!--<div class="next-prev">
                <button class="prev" (click)="prev()"><</button>
                <button class="next" (click)="next()">></button>
            </div>-->



        </div>
        <div class="column-product">
            <div class="product-name">{{productShown.name}}</div>

            <div class="product-info">{{productShown.description}}</div>

            <div class="product-price">{{productShown.price}} €*</div>
            <div class="product-ddv" *ngIf="productShown.product_last_lowest_price > 0 &&
              productShown.product_last_lowest_price != productShown.action_price &&
              productShown.product_last_lowest_price != productShown.price">Lowest price in last 30 days: {{productShown.product_last_lowest_price}}€</div>
            <div class="product-ddv">VAT Included<br>Shipping not included!</div>
            <div class="product-ddv">The prices on the website correspond to - unless specifically marked for individual products - the lowest price in the period of the last 30 days prior to the price reduction.</div>

            <ng-container *ngIf="productShown && productShown.options && productShown.options.length > 0" >
            <div *ngFor="let optionToChoose of productShown?.options">
                <p class="p-product-variation-name">{{optionToChoose.varname}}</p>
                <div class="option-svgs">
                <ng-container *ngFor="let value of optionToChoose.values">

                        <ng-container *ngFor="let kv of optionSelected.variation | keyvalue">
                            <ng-container *ngIf="kv.key == optionToChoose.varname">
                                    <svg class="btn-select-variation" [class.selected]="kv.value == value" [attr.viewBox]="'0 0 ' + (getTextWidth(value) + 9) + ' ' + (getTextHeight(value)+10)" style="height:60px; cursor: pointer" (click)="selectVariant(optionToChoose.varname, value)">
                                        <rect x="2" y="5" fill="white" stroke="black" stroke-width="2" [attr.height]="getTextHeight(value)" [attr.width]="getTextWidth(value)" />
                                        <rect x="5" y="2" fill="white" class="select-variation-rect" stroke="black" stroke-width="2" [attr.height]="getTextHeight(value)" [attr.width]="getTextWidth(value)" />
                                        <text x="8" y="20" font-family="PPNeueBit-Bold" font-size="24" class="select-variation-text" fill="black">{{ value }}</text>
                                    </svg>
                                   <!--<button *ngIf="kv.value == value" class="btn-black-option selected" (click)="selectVariant(optionToChoose.varname, value)">{{value}}</button>
                                    <button *ngIf="kv.value != value" class="btn-black-option" (click)="selectVariant(optionToChoose.varname, value)">{{value}}</button>-->
                            </ng-container>
                        </ng-container>


                </ng-container>
                </div>
            </div>
            </ng-container>
            <div style="color:red;" class="out-of-stock" [hidden]="(productShown.options.length > 0 && optionSelected.stock > 0) || (productShown.options.length <= 0 && productShown.general_stock > 0)"><h2>Out of stock!</h2></div>

            <div class="mobile-buttons">
                <mat-form-field>
                    <button mat-button style="border: 0; font-size: 35px;" matSuffix (click)="increment()">+</button>
                    <input matInput type="number" style="text-align: center; margin-top: 6px" value="{{amountSelected}}" min="0" max="{{(productShown.options.length > 0) ? optionSelected.stock : productShown.general_stock}}" (change)="changeSelectedAmount($event)">
                    <button mat-button style="border: 0; font-size: 35px" matPrefix (click)="decrement()">-</button>
                </mat-form-field>
                <button class="btn-add-to-cart" [disabled]="(productShown.options.length > 0 &&(optionSelected.stock <= 0 || optionSelected.stock < amountSelected))||(productShown.options.length <= 0&&(productShown.general_stock <= 0 || productShown.general_stock < amountSelected))" (click)="addToCart()"><img src="assets/img/gumbi-13.svg"></button>
            </div>
        </div>
    </div>
</div>

<app-footer-web></app-footer-web>
