import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./Website/home/home.component";
import {CartComponent} from "./Website/cart/cart.component";
import {InfoComponent} from "./Website/info/info.component";
import {MerchComponent} from "./Website/merch/merch.component";
import {ProductComponent} from "./Website/product/product.component";
import {TourComponent} from "./Website/tour/tour.component";
import {AdminLayoutComponent} from "./AdminPanel/admin-layout/admin-layout.component";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {PaymentSuccessComponent} from "./Website/payment-success/payment-success.component";
import {PaymentFailedComponent} from "./Website/payment-failed/payment-failed.component";
import {LoginComponent} from "./Website/login/login.component";
import {AuthGuard} from "./shared/auth.guard";
import {SelfRegisterComponent} from "./Website/self-register/self-register.component";
import {ReturnAndRefundComponent} from "./Website/return-and-refund/return-and-refund.component";
import {TermsOfBusinessComponent} from "./Website/terms-of-business/terms-of-business.component";
import {PrivacyPolicyComponent} from "./Website/privacy-policy/privacy-policy.component";
import {CompanyInformationComponent} from "./Website/company-information/company-information.component";
import {UnsubscribeComponent} from "./Website/unsubscribe/unsubscribe.component";


const routes: Routes = [
  {path:"", component: HomeComponent},
  {path:"home", component: HomeComponent},
  {path:"cart", component: CartComponent},
    {path:"payment/success", component:PaymentSuccessComponent},
    {path:"payment/failed", component:PaymentFailedComponent},
  {path:"info", component: InfoComponent},
  {path:"merch", component: MerchComponent},
  {path:"product/:id", component: ProductComponent},
  {path:'cart', component:CartComponent},
  {path:"tour", component: TourComponent},
  {path:"login", component: LoginComponent},
  {path:"register", component: SelfRegisterComponent},
  {path:"return-and-refund", component: ReturnAndRefundComponent},
  {path:"terms-of-business", component: TermsOfBusinessComponent},
  {path:"privacy-policy", component: PrivacyPolicyComponent},
  {path:"company-information", component: CompanyInformationComponent},
  {path:"admin", component:AdminLayoutComponent, loadChildren:()=> import('./AdminPanel/admin-layout/admin-layout.module').then(m=>m.AdminLayoutModule)},
  {path:"unsubscribe/:id", component:UnsubscribeComponent}
];

@NgModule({
  imports: [CommonModule,
      BrowserModule,
      RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
