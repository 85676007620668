<app-header></app-header>
<div class="container-tob">
    <div class="row-tob">
        <h1 class="title-tob">Company Information</h1>
        <div class="info-top">Name:</div>
        <div class="info-sub">Prjatučki d.o.o.</div>
        <div class="info-top">Headquarters:</div>
        <div class="info-sub">Ob Težki vodi 44A, 8000 Novo Mesto, Slovenija</div>
        <div class="info-top">Registration number:</div>
        <div class="info-sub">9310487000</div>
        <div class="info-top">Tax ID:</div>
        <div class="info-sub">SI59787066</div>
        <div class="info-top">Registered at:</div>
        <div class="info-sub">Okrožno sodišče Novo mesto</div>

    </div>
</div>
<app-footer-web></app-footer-web>
