<!-- HEADER -->
<app-header></app-header>
<!-- BODY -->

<div class="container-merch">
    <div class="row-merch">
        <div class="column-merch" *ngFor="let product of products" (click)="goToProduct(product.id)">
            <div class="image-container">
                <img *ngIf="product.headliner_image.length > 0" class="merch-image" src="assets/uploads/{{product.headliner_image[0]}}">
            </div>
            <div class="merch-product">{{product.name}}</div>
            <div class="merch-price">{{product.price}}€</div>
            <!--<div class="merch-price" *ngIf="product.product_last_lowest_price > 0">
              Najnižja cena v zadnjih 30 dneh: {{product.product_last_lowest_price}}€
            </div>-->
        </div>
    </div>


</div>





<app-footer-web></app-footer-web>
