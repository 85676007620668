import {Customer} from "../Model/CustomerModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { environment } from '../../environments/environment';
import {Product} from "../Model/ProductModel";

@Injectable({
	providedIn: "root"
})
export class CustomerService{

	constructor(private HttpClient: HttpClient) {
	}

	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}

	async getMatchingCustomer(customer:Customer){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve=>{
			this.HttpClient.get<any>(environment.apiHost+environment.apiPath+"customers?filter="+JSON.stringify(customer), httpOptions)
				.subscribe(response=>{
					resolve(response.result);
				})
		});
	}

	async createNewCustomer(customer:Customer){
		const httpOptions = this.getHttpOptions();
		return new Promise(resolve => {
			this.HttpClient.post(environment.apiHost+environment.apiPath+"customers", customer, httpOptions)
				.subscribe(response=>{
					resolve(response);
				})
		})
	}
}
