import {Order} from "../Model/OrderModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Customer} from "../Model/CustomerModel";
import {CartItem} from "../Model/CartItemModel";
import {PaymentM} from "../Model/Payment.model";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class OrderService{
    constructor(private HttpClient:HttpClient){}


    getHttpOptions(){
        let jwt = localStorage.getItem("MRFYJWT");
        if(!jwt) jwt="No Cookie Found";
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: jwt
            })
        }
        return httpOptions;
    }

    async createNewOrder(order:Order){
        return new Promise(resolve => {
            this.HttpClient.post(environment.apiHost+environment.apiPath+"order", order, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }

    async createNewOrderItems(orderItem:CartItem | CartItem[]){
        return new Promise(resolve => {
            this.HttpClient.post(environment.apiHost+environment.apiPath+"orderItem", orderItem, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }

    async updateOrder(order:Order){
        return new Promise(resolve => {
            this.HttpClient.put(environment.apiHost+environment.apiPath+"order/"+order.id, order, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }

    async getOrders(page=1, filter={},  itemsPerPage=25, sorting={}){
        return new Promise(resolve => {
            this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'order?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page+'&sorting='+JSON.stringify(sorting), this.getHttpOptions())
                .subscribe(response=>{
                    response.result.forEach((result:any)=>{
                        result.recipient_address = JSON.parse(JSON.stringify(result.recipient_address));
                    })
                    resolve(response);
                })
        })
    }
    async getOrderById(id:number){
        return new Promise(resolve => {
            this.HttpClient.get<any>(environment.apiHost+environment.apiPath+"order/"+id, this.getHttpOptions())
                .subscribe(response=>{
                    response.forEach((result:any)=>{
                        result.recipient_address = JSON.parse(JSON.stringify(result.recipient_address));
                    })
                    resolve(response[0]);
                })
        })
    }

    async newOrderPlaced(customer:Customer, order:Order, cartItems:CartItem[], payment?:PaymentM){
        return new Promise(resolve => {
            const bodyToSend:any = {
                customer: customer,
                order: order,
                cartItems: cartItems,
            }
            if(payment != undefined) bodyToSend.payment = payment;

            console.log(bodyToSend);
            this.HttpClient.post(environment.apiHost+environment.apiPath+"order/placed", bodyToSend, this.getHttpOptions())
                .subscribe(response=>{
                    resolve(response);
                })
        })
    }
}
