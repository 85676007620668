import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {CartService} from "../../Service/CartService.service";
import {CartItem} from "../../Model/CartItemModel";
import {HttpClient} from "@angular/common/http";
import {loadStripe} from "@stripe/stripe-js";
import {environment} from "../../../environments/environment";
import { MatDialog } from '@angular/material/dialog';
import {SubscribeComponent} from "../../AdminPanel/popups/subscribe/subscribe.component";
import {SubscriptionModel} from "../../Model/SubscriptionModel";
import {PreorderPopupComponent} from "../../AdminPanel/popups/preorder-popup/preorder-popup.component";
import {Customer} from "../../Model/CustomerModel";
import {Address} from "../../Model/Address";
import {Order} from "../../Model/OrderModel";
import {OrderService} from "../../Service/Order.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {

  cartItems : Array<CartItem>;
  cartPrice : number = 0;
  totalProducts:number=0;
  totalWeight: number = 0;
  public vat: number = 0;
  public shipmentPrice = 0
  public termsAgreement = false;

  constructor(private cartService:CartService, private http:HttpClient, public popup: MatDialog, public orderService:OrderService, private snackBar: MatSnackBar, private router:Router) {
    this.cartItems = new Array<CartItem>();
  }

  ngOnInit(): void {
    this.refreshCart();
    this.termsAgreement = false;
  }

  changeCartAmount(itemId:number, event:any){
    console.log("Item id "+itemId+" New amount: " + event.target.value)
    this.cartService.changeItemQuantity(itemId, parseInt(event.target.value));
    this.refreshCart();
  }
  removeFromCart(itemId:number){
    //console.log("Removing id: " + itemId);
    this.cartService.removeFromCart(itemId);
    this.refreshCart();
  }
  refreshCart(){
    this.cartItems = this.cartService.getItems();
    this.totalWeight = this.cartService.getCartWeight();
    this.totalProducts = this.cartItems.length;
    this.cartPrice = this.cartService.getCartPrice();
    this.shipmentPrice = this.calculateShippingPrice();

    let cartBase = this.cartPrice/1.22;
    let cartVat = this.cartPrice - cartBase;
    this.cartPrice = cartBase;

    this.vat = cartVat + this.shipmentPrice*0.22;
   // this.cartPrice = cartBase + this.vat + this.shipmentPrice;
    //this.shipmentPrice = this.shipmentPrice/1.22; //Shipment price is given in nett



    console.log(this.cartItems);
  }

  calculateShippingPrice(){
    for (let i = 0; i < environment.shipmentPrices.length; i++) {
      const price = environment.shipmentPrices[i];
      if (this.totalWeight >= price.minWeight && this.totalWeight <= price.maxWeight) {
        return price.price;
      }
    }
    return 0;
  }
  onCheckout(){
    if(environment.webshopEnabled){

      this.http.post(environment.apiHost+environment.paymentGatewayAPIPath+'checkout', {
        items: this.cartItems
      }).subscribe(async(res:any)=>{
        localStorage.setItem("stripeSession", res.id);
        let stripe = await loadStripe(environment.stripe_key);
          stripe?.redirectToCheckout({
            sessionId: res.id
          }
        )
      })
    }else{
      const popup = this.popup.open(PreorderPopupComponent, {
        width:'450px',
        data: new Customer(0, "", 0, "", "", new Address("", "", "", "", "", ""))
      });

      popup.afterClosed().subscribe(async (result: Customer) => {
        if (result) {
          let newOrder = new Order(0, result, 1, new Date(), "", result.address);
          this.snackBar.open("Your order has been initialized! Wait untill the order is placed completely and you will be redirected to the home page!");
          await this.orderService.newOrderPlaced(result, newOrder, this.cartService.getItems(), undefined);
          this.cartService.clearCart();
          let addToCartSnackBar = this.snackBar.open("Your order has been completed! After all of the items are available you will receive a payment request!");
          localStorage.removeItem("mrfyWebCart");
          localStorage.removeItem("stripeSession");
          this.router.navigate(["home"]);
        }
      })
    }
  }

}
