export class CartItem {
    public id: number;
    public product_id:number;
    public product_name:string;
    public order_id:number;
    public options: any;
    public quantity:number;
    public price:number;
    public details:string;
    public status_code:number;
    public headliner_image: string[];
    public stock:number;
    public weight: number;


    constructor(
        id: number,
        product_id:number,
        product_name:string,
        order_id:number,
        options: any,
        quantity:number,
        price:number,
        details:string,
        status_code:number,
        headliner_image: string[],
        stock:number,
        weight: number
    ) {
        this.id = id;
        this.product_id=product_id;
        this.product_name=product_name;
        this.order_id=order_id;
        this.options=options;
        this.quantity=quantity;
        this.price=price;
        this.details=details;
        this.status_code=status_code;
        this.headliner_image=headliner_image;
        this.stock = stock;
        this.weight = weight;
    }

}
