<!-- HEADER -->
<app-header></app-header>
<!-- BODY -->
<div class="form-container">
    <div class="">
        <div class="row-form">
            <div class="form-title">LOGIN</div>
        </div>
        <div class="row-form">
            <label for="email">Email</label>
            <input type="text" placeholder="E-mail" name="email" id="email" required (change)="setUsername($event)">
        </div>
        <div class="row-form">
            <label for="password">Password</label>
            <input type="password" placeholder="Password" name="Password" id="password" required (change)="setPassword($event)">
        </div>
        <div class="row-form">
            <div class="form">Forgot <a class="form-links" href="/registration">password?</a></div>
        </div>
        <div class="row-form">
            <button type="submit" class="login-btn" (click)="login()">LOGIN</button></div>
        <div class="row-form" >
            <div class="form">Don't have an account? <a class="form-links" href="/registration">Click here</a></div>
        </div>
    </div>
</div>

        <app-footer-web></app-footer-web>
