import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CartService} from "../../../Service/CartService.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 cartItemsCount:number = 0;
  constructor(public router:Router, private cartService:CartService, private cd:ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getTotalCartItemsNumber();
    this.cartService.cartChanged.subscribe(()=>{
        this.getTotalCartItemsNumber();
        this.cd.detectChanges();
    })
  }

  private getTotalCartItemsNumber(){
      this.cartItemsCount = 0;
      this.cartService.getItems().forEach((item)=>{this.cartItemsCount += item.quantity});
  }

}
