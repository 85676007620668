import {ShipmentItem} from "../Model/ShipmentItemModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class ShipmentItemService{

	constructor(private HttpClient: HttpClient){}

}