import { Component, OnInit } from '@angular/core';
import {Employee} from "../../Model/EmployeeModel";
import {Address} from "../../Model/Address";
import {EmployeeService} from "../../Service/EmployeeService";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-self-register',
  templateUrl: './self-register.component.html',
  styleUrls: ['./self-register.component.css']
})
export class SelfRegisterComponent implements OnInit {
    public employee:Employee;
  constructor(private employeeService:EmployeeService, private snackBar: MatSnackBar) {
      this.employee = new Employee(0, "", "", new Address("", "", "", "", "", ""), "", "", "", new Date(), false);
  }

  ngOnInit(): void {
  }

  public register = async ()=>{
      try{
          console.log(this.employee);
          await this.employeeService.registerUser(this.employee);
          this.snackBar.open("Successfully registered as a MRFY employee! When one of the administrators accepts you you will be able to login!");
      }catch(e:any){
          console.log(e);
          this.snackBar.open(e);
      }
  }

}
